import { GraphQLError } from 'graphql';
import React from 'react';

interface GraphQLErrorListProps {
  errors: Array<GraphQLError>;
}

const GraphQLErrorList = ({ errors }: GraphQLErrorListProps) => (
  <div>
    <h1>GraphQL Error</h1>
    {errors.map(error => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
);

export default GraphQLErrorList;
