import { format, formatDistance, differenceInDays } from 'date-fns';
import { nl } from 'date-fns/locale';
import Img, { FluidObject } from 'gatsby-image';
import React, { ReactElement } from 'react';
import {
  SanityPost,
  SanityCategory,
  Maybe,
} from '../../generated/graphql-types';
import Heading from '@csv/styleguide/src/elements/heading/heading';

import PortableText from './../../components/portable-text';
import styled from 'styled-components';
import { Chip } from '@csv/styleguide/src/elements/chip/chip';

const StyledArticle = styled.article`
  padding: 2em 1em; // @TODO switch all units to rem here
  @media screen and (min-width: 520px) {
    padding-top: 4em;
  }
`;

const ArticleHeader = styled.header`
  max-width: 700px;
  margin: 0 auto;

  @supports (max-width: 70ch) {
    max-width: 70ch;
  }

  ${Heading} {
    text-align: center;
    font-weight: 900;
  }
`;

const StyledArticlePublishedAtBy = styled.span`
  display: block;
  font-size: 14px;
  text-align: center;
  margin-bottom: 2em;

  time {
  }
  span {
    display: block;
  }
`;

const ArticleCategories = styled.div`
  display: flex;
  justify-content: center;
  ${Chip} {
    margin: 0 0.25em;
  }
`;

interface ArticlePublishedAtByProps {
  datetime: string;
  author?: string | null;
}

const ArticlePublishedAtBy = ({
  datetime,
  author,
}: ArticlePublishedAtByProps) => {
  return (
    <StyledArticlePublishedAtBy>
      Gepubliceerd op{' '}
      <time>
        {differenceInDays(new Date(datetime), new Date()) > 3
          ? formatDistance(new Date(datetime), new Date(), {
              locale: nl,
            })
          : format(new Date(datetime), 'EEEE d MMMM yyyy', {
              locale: nl,
            })}
      </time>
      {author && <span> door {author}</span>}
    </StyledArticlePublishedAtBy>
  );
};

const ArticleBody = styled.div`
  max-width: 700px;
  margin: 0 auto;

  @supports (max-width: 70ch) {
    max-width: 70ch;
  }

  ${Heading} {
    margin-top: 1.5em;
  }
`;

const ArticleMainImage = styled.figure`
  max-width: 1170px;
  /* max-height: 600px; */
  display: block;
  margin: 2em auto;
  border-radius: 4px;
  overflow: hidden;
`;

type ArticleProps = Pick<
  SanityPost,
  '_rawBody' | 'title' | 'mainImage' | 'publishedAt'
> & {
  authorName?: string | null;
  categories: Maybe<Array<Maybe<Pick<SanityCategory, '_id' | 'title'>>>>; // FML gatsby sanity types..
};

const Article = ({
  _rawBody,
  authorName,
  categories,
  title,
  mainImage,
  publishedAt,
}: ArticleProps): ReactElement => {
  return (
    <StyledArticle>
      <ArticleHeader>
        <Heading as="h1" variant="h1">
          {title}
        </Heading>
        <ArticlePublishedAtBy datetime={publishedAt} author={authorName} />
        <ArticleCategories>
          {categories?.map((cat, i) => (
            <Chip key={cat?._id || i}>{cat?.title || ''}</Chip>
          ))}
        </ArticleCategories>
      </ArticleHeader>
      {mainImage?.image?.asset?.fluid && (
        <ArticleMainImage>
          <Img
            // Typecasting is needed here, see: https://github.com/sanity-io/gatsby-source-sanity/issues/57
            fluid={mainImage.image.asset.fluid as FluidObject}
            alt={mainImage.alt as string}
          />
        </ArticleMainImage>
      )}

      {_rawBody && (
        <ArticleBody>
          <PortableText blocks={_rawBody} />
        </ArticleBody>
      )}
    </StyledArticle>
  );
};
export default Article;
