import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import Article from '../features/article/article';
import Layout from '../components/layout';
import SEO from '../components/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import { BlogPostTemplateQueryQuery } from '../generated/graphql-types';
import { GraphQLError } from 'graphql';

interface Props {
  data: BlogPostTemplateQueryQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: Array<GraphQLError>;
}

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        image {
          asset {
            fluid(maxWidth: 1170) {
              ...GatsbySanityImageFluid
            }
          }
        }
        alt
        caption
      }
      title
      slug {
        current
      }
      seo {
        meta_description
        seo_title
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        name
      }
    }
  }
`;

export default function Post({ data, errors }: Props): ReactElement {
  return (
    <Layout>
      {errors && (
        <SEO title="GraphQL Error" description="something went wrong" />
      )}
      {errors && (
        <div>
          <GraphQLErrorList errors={errors} />
        </div>
      )}
      {data && data.post && (
        <>
          {/* {@TODO ADD SEO } */}
          <Article
            _rawBody={data.post._rawBody}
            authorName={data.post.author?.name}
            categories={data.post.categories}
            title={data.post.title}
            mainImage={data.post.mainImage}
            publishedAt={data.post.publishedAt}
          />
        </>
      )}
    </Layout>
  );
}
